 export class ToggleNotificationButton extends dk.Widget {
    constructor(...args) {
        // check that user sent properties (required)
        if (args.length === 1 && args[0] === undefined) {
            throw new Error(`
                Please specify the user.
                
                Example:

                    const mybutton = finautalert.ToggleNotificationButton.create_on('#my_button', {
                        userid: {{ user.id|jsonval }},
                        newsalert: {{ user.newsalert|jsonval }}
                    });
            `)
        }

        const props = Object.assign(...args);
        super(props);

        this.userid = props.userid || null;
        this.newsalert = props.newsalert;
        this.toggle_url = props.toggle_url || '/finautalert/toggle-newsalert/';
    }      
    
    construct() {
        this.btn = this.layout.location;
    }

    start_busy() {
        this.btn.prop('disabled', true);
        // this.btn.html(`<dk-icon value="spinner" animation="spin"></dk-icon>`);  
    }

    end_busy() {
        this.btn.prop('disabled', false);
    }

    on() {
        this.btn.removeClass('btn-danger').addClass('btn-success');
        this.btn.text('E-postvarsel på');
    }

    off() {
        this.btn.removeClass('btn-success').addClass('btn-danger');
        this.btn.text('E-postvarsel av');            
    }

    update(val) {
        this.newsalert = val;
        this.draw();
    }

    toggle() {
        this.start_busy();
        const self = this;
        dk.json({
            url: self.toggle_url,
            data: {
                userid: this.userid,
            },
            success: function (response) {
                if (response.kind === "success") {
                    self.update(response.data.value);
                } else if (response.kind === 'ERROR') {
                    alert('Noe gikk galt. Vennligst oppfrisk siden og prøv igjen. Ta kontakt med FinAut på post@finaut.no dersom problemet vedvarer.');
                }
                self.end_busy();
            },
        });
    }

    handlers() {
        this.btn.on('click', () => this.toggle());
    }

    draw() {
        this.newsalert ? this.on() : this.off();
    }
}
